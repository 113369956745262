//React
import React from "react";
//Logo
import Logo from "../assets/Logos/IPOscuro.png";
//Cambio de Idioma
import { useIdioma } from './IdiomaContext';
import textosEspañol from './TextosEspañol';
import textosIngles from './TextosIngles';
import textosChino from './TextosChino';

function Servicios(){
  const { idioma } = useIdioma();
  let textosIdioma;
    switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }
  return (
    <>
    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S1.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S1.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://s3.eu-west-2.amazonaws.com/transeop/Blog/180.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S2.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S2.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://www.comercioyaduanas.com.mx/wp-content/uploads/2015/04/porque-empezar-un-negocio-importando-o-exportando.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>
  
    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S3.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S3.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://bandalibre.es/wp-content/uploads/2016/01/Siete-recomendaciones-al-crear-un-negocio-de-servicios-de-Internet.png" alt="Imagen" className="centered-image"/>
      </div>
    </div>


    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S4.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S4.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://pepperandsalt.mx/wp-content/uploads/2020/04/alquiler-de-bodegas-temporales-770x433.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S5.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S5.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://funciondelaindustria.files.wordpress.com/2018/05/maniobras-de-izaje.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S6.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S6.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://hlworldwidelog.com/wp-content/uploads/2020/12/seguro.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S7.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S7.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://www.beetrack.com/hubfs/Importacion%20de%20productos.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S8.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S8.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://thefoodtech.com/wp-content/uploads/2021/08/certificados-calidad-seguridad-industria-alimentaria.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S9.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S9.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://www.tlcasociados.com.mx/wp-content/uploads/2020/03/P%C3%A1gina-WEB_Certificaci%C3%B3n-OEA-1.4_-.png" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className="servicio">  
      <div className="text-servicio">
        <div className="text-serv">
          <h1>{textosIdioma.S10.titulo}<span></span><span></span><span></span><span></span></h1>
          <p>{textosIdioma.S10.descripcion}</p>
        </div>
      </div>
      <div className="image-servicio">
        <img src="https://pepperandsalt.mx/wp-content/uploads/2020/07/BLOG-JOAQU%C3%8DN.jpg" alt="Imagen" className="centered-image"/>
      </div>
    </div>

    <div className='servicios1'>
      <h2>{textosIdioma.puntosDeServicio.titulo}</h2>
      <li>{textosIdioma.puntosDeServicio.lista[0]}</li>
      <li>{textosIdioma.puntosDeServicio.lista[1]}</li>
      <li>{textosIdioma.puntosDeServicio.lista[2]}</li>
      <li>{textosIdioma.puntosDeServicio.lista[3]}</li>
      <li>{textosIdioma.puntosDeServicio.lista[4]}</li>
      <li>{textosIdioma.puntosDeServicio.lista[5]}</li>
      <li>{textosIdioma.puntosDeServicio.lista[6]}</li>
    </div>
    </>
  );
}
export default Servicios;