//React
import React from "react";
//Logos
/*import Logo2 from "../assets/Logos/IPBlanco.png";*/
//Cambio de Idioma
import { useIdioma } from './IdiomaContext';
import textosEspañol from './TextosEspañol';
import textosIngles from './TextosIngles';
import textosChino from './TextosChino';

function Footer(){
    const { idioma } = useIdioma();
    let textosIdioma;
      switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }
    return(
    <div className="footer">
        <footer className="footer1">
            <div className="waves">
            <div className="wave" id="wave2"></div>
            <div className="wave" id="wave3"></div>
            <div className="wave" id="wave4"></div>
            </div>
            <p>{textosIdioma.footer}</p>
            <p>{textosIdioma.footer1} </p><p>{textosIdioma.footer2}</p>
            
        </footer>
    </div>
    );
}
export default Footer;