import React, { useState } from 'react';
import '../estilos/principal.css';
//Cambio de Idioma
import { useIdioma } from './IdiomaContext';
import textosEspañol from './TextosEspañol';
import textosIngles from './TextosIngles';
import textosChino from './TextosChino';
//GIF
import Cargando from '../assets/Cargando2.gif';

import S1 from '../assets/Carrusel/1.svg';
import S2 from '../assets/Carrusel/2.svg';
import S3 from '../assets/Carrusel/3.svg';
import S4 from '../assets/Carrusel/4.svg';
import S6 from '../assets/Carrusel/6.svg';

const DatosComponent = ({ datos, idioma }) => {
  const { foundeds } = datos;
  if (!Array.isArray(foundeds)) { return <p>No hay datos disponibles.</p>; }
  let textosIdioma;
  switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }
  const TimelineEvent = ({ image, header, content }) => {
    return (
      <div className="timeline-event">
        <div className="event-image">
          <img src={image} alt="Imagen del evento" />
        </div>
        <div className="event-info">
          <div className="event-header">{header}</div>
          <div className="event-content">{content}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {foundeds.map((envio, index) => (
        <div key={index}>
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan="2" className="table-title">{textosIdioma.R0}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>{textosIdioma.R2}</strong> {envio.REFERENCE} </td>
                <td><strong>MBL</strong> {envio.MBL} </td>
              </tr>
              <tr>
                <td><strong>{textosIdioma.R4}</strong> {envio.CONTAINER} </td>
                <td><strong>{textosIdioma.R5}</strong> {envio.REMOLQUE} </td>
              </tr>
              <tr>
                <td><strong>{textosIdioma.R6}</strong> {envio.POL} </td>
                <td><strong>{textosIdioma.R7}</strong> {envio.POD} </td>
              </tr>
              <tr>
                <td><strong>{textosIdioma.R8}</strong> {envio.MODALIDAD} </td>
                <td><strong>{textosIdioma.R9}</strong> {envio.DESTINATION} </td>
              </tr>
            </tbody>
          </table>
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan="2" className="table-title">{textosIdioma.R10}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>{textosIdioma.R11}</strong> {envio.ADUANA} </td>
                <td><strong>{textosIdioma.R12}</strong> {envio.SALIDAREAL} </td>
              </tr>
              <tr>
                <td><strong>{textosIdioma.R13}</strong> {envio.ETA} </td>
                <td><strong>{textosIdioma.R14}</strong> {envio.EIR} </td>
              </tr>
              <tr>
                <td><strong>{textosIdioma.R15}</strong> {envio.ARRIVAL} </td>
                <td><strong>{textosIdioma.R16}</strong> {envio.OFFLOADER} </td>
              </tr>
              <tr>
                <td><strong>{textosIdioma.R17}</strong> {envio.EMPTYRETURN} </td>
                <td><strong>{textosIdioma.R18}</strong> {envio.RETURN} </td>
              </tr>
            </tbody>
          </table>
          <div className="timeline-container"> 
              <TimelineEvent image={S1} header="POL" content={envio.POL} />
              <TimelineEvent image={S2} header="POD" content={envio.POD + " " + (!envio.CCLARENCE?"":envio.CCLARENCE)} />
              <TimelineEvent image={S6} header={textosIdioma.R45} content={envio.ADUANA + ' ' + envio.TERMINAL} />
              <TimelineEvent image={S3} header={textosIdioma.R46} content={envio.ARRIVAL} />
              <TimelineEvent image={S4} heSader={textosIdioma.R47} content={envio.EMPTYRETURN + ' ' + envio.EIR} />
            </div>
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan="2" className="table-title">{textosIdioma.R20}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>{textosIdioma.R21}</strong> {envio.CONTAINER} </td>
                <td>{textosIdioma.R22}<strong>{envio.STATUS}</strong>{" ("+envio.FECHASTATUS+")"}</td>
              </tr>
              {/* <tr>
                <td> </td>
                <td>{envio.FECHASTATUS} </td>
              </tr> */}
            </tbody>
            <caption>{textosIdioma.R19} {index + 1}</caption>
          </table>
        </div>
      ))}
    </div>
  );
};
const DatosComponent2 = ({ datos, idioma }) => {
  const { foundeds } = datos;
  const [isTable1Open, setIsTable1Open] = useState(false);
  const [isTable2Open, setIsTable2Open] = useState(false);

  if (!Array.isArray(foundeds)) { return <p>No hay datos disponibles.</p>; }
  let textosIdioma;
  switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }
  const TimelineEvent = ({ image, header, content }) => {
    return (
      <div className="timeline-event">
        <div className="event-image">
          <img src={image} alt="Imagen del evento" />
        </div>
        <div className="event-info">
          <div className="event-header">{header}</div>
          <div className="event-content">{content}</div>
        </div>
      </div>
    );
  };

  const toggleTable1 = (index) => {
    setIsTable1Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  const toggleTable2 = (index) => {
    setIsTable2Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <>
      <div>
        {foundeds.map((envio, index) => (
          <div key={index}>
            <table className="custom-table">
              <thead>
                <tr>
                  <th colSpan="2" className="table-title">{textosIdioma.R1}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>{textosIdioma.R2}</strong> {envio.REFERENCE}</td>
                  <td><strong>{textosIdioma.R23}</strong> {envio.LUGARDECARGA}</td>
                </tr>
                <tr>
                  <td><strong>{textosIdioma.R6}</strong> {envio.POL}</td>
                  <td><strong>{textosIdioma.R24}</strong> {envio.FECHADECARGAENFABRICA}</td>
                </tr>
                <tr>
                  <td><strong>{textosIdioma.R3}</strong> {envio.MBL}</td>
                  <td><strong>{textosIdioma.R25}</strong> {envio.NOMBREDELBOK}</td>
                </tr>
                <tr>
                  <td><strong>{textosIdioma.R7}</strong> {envio.POD}</td>
                  <td><strong>{textosIdioma.R26}</strong> {envio.ARRIVODESTINO}</td>
                </tr>
              </tbody>
            </table>
            <div className="acordeon-container">
              <button className={`acordeon-button ${isTable1Open[index] ? 'open' : ''}`} onClick={() => toggleTable1(index)}>{textosIdioma.R27}</button>
              {isTable1Open[index] && (
                <div className="acordeon-content">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th colSpan="2" className="table-title">{textosIdioma.R27}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>{textosIdioma.R4}</strong> {envio.CONTENEDOR}</td>
                        <td><strong>{textosIdioma.R8}</strong> {envio.MODALIDAD}</td>
                      </tr>
                      <tr>
                        <td><strong>{textosIdioma.R28}</strong> {envio.RECOLECCIÓN}</td>
                        <td><strong>{textosIdioma.R29}</strong> {envio.TOMAVACIOS}</td>
                      </tr>
                      <tr>
                        <td><strong>{textosIdioma.R30}</strong> {envio.INICIARUTA}</td>
                        <td><strong>ETA: </strong> {envio.ETA}</td>
                      </tr>
                      <td><strong>{textosIdioma.R31}</strong> {envio.FECHALLEGADAFABRICA}</td>
                      <td><strong>{textosIdioma.R32}</strong> {envio.TERMINOCARGA}</td>
                      <tr>
                      </tr>
                      <td><strong>{textosIdioma.R33} </strong> {envio.FECHALLEGADAPUERTO}</td>
                      <td><strong>EIR: </strong> {envio.EIR}</td>
                      <tr>
                        <td><strong>{textosIdioma.R34} </strong> {envio.FECHAINGRESOTERMINAL}</td>
                        <td><strong>{textosIdioma.R35} </strong> {envio.TERMINAL}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <button className={`acordeon-button ${isTable2Open[index] ? 'open' : ''}`} onClick={() => toggleTable2(index)}>{textosIdioma.R36}</button>
              {isTable2Open[index] && (
                <div className="acordeon-content">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th colSpan="2" className="table-title">{textosIdioma.R36}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>{textosIdioma.R37} </strong> {envio.VEHICULO}</td>
                        <td><strong>{textosIdioma.R38} </strong> {envio.FECHALLEGADACAMIONCARGA}</td>
                      </tr>
                      <tr>
                        <td><strong>{textosIdioma.R39} </strong> {envio.CONCLUSIONCARGA}</td>
                        <td><strong>{textosIdioma.R40} </strong> {envio.FECHAARRIVOADUANAEUA}</td>
                      </tr>
                      <tr>
                        <td><strong>{textosIdioma.R41} </strong> {envio.USACRUCEFRONTERA}</td>
                        <td><strong>{textosIdioma.R42} </strong> {envio.CANCRUCEFRONTERA}</td>
                      </tr>
                      <tr>
                        <td><strong>{textosIdioma.R43} </strong> {envio.FECHAARRIVODESTINOFINAL}</td>
                        <td><strong>{textosIdioma.R44} </strong> {envio.FECHAHORAFINALDECARGA}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="timeline-container"> 
              <TimelineEvent image={S1} header="POL" content={envio.POL} />
              <TimelineEvent image={S2} header={textosIdioma.R45} content={envio.POL + ' ' + envio.CCLARENCE} />
              <TimelineEvent image={S3} header="POD" content={envio.POD} />
            </div>
            <table className="custom-table">
              <thead>
                <tr>
                  <th colSpan="2" className="table-title">{textosIdioma.R20}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>{textosIdioma.R21}</strong> {envio.CONTENEDOR} </td>
                  <td>{textosIdioma.R22}<strong>{envio.STATUS}</strong></td>
                </tr>
                <tr>
                  <td></td>
                  <td>{envio.FECHASTATUS} </td>
                </tr>
              </tbody>
              <caption>{textosIdioma.R19} {index + 1}</caption>
            </table>
          </div>
        ))}
      </div></>
  );
};

const Rastreo = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [Expo, setExpo] = useState(false);
  const [Impo, setImpo] = useState(true);
  const [showData, setShowData] = useState(null);
  const [datos, setDatos] = useState('');
  const [searchType, setSearchType] = useState('referencia');
  const [searchType2, setSearchType2] = useState('importacion');
  const [selectedOptionValue, setSelectedOptionValue] = useState('referencia');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDatos((prevDatos) => ({
      ...prevDatos
    }));
    setLoading(true);
  };
  const fetchData = async () => {
    const searchTermEnM = searchTerm.toUpperCase();
    setLoading(true);
    const dataa = {
      action: 'tracking',
      forma: forma,
      id_tracking: searchTermEnM.toUpperCase().trim()
    };
    try {
      const response = await fetch(forma2, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataa),
      });
      setShowData(false);
      const jsonResponse = await response.json();
      alert(jsonResponse)
      
      setDatos(jsonResponse);
      if (response.ok) {
        if (jsonResponse.status === "true") {
          setDatos(jsonResponse);
          setShowData(true);
        } else {
          setShowData(false);
        }
      }
      console.log(jsonResponse);
    } catch (error) {
      setShowData(false);
      console.log('Error en la solicitud:', error);
    }
    if (searchType2 === 'importacion') { setImpo(true); setExpo(false); }
    else if (searchType2 === 'exportacion') { setImpo(false); setExpo(true); }
    setLoading(false);
  };

  const handleSearchTypeChange = (event) => {
    const value = event.target.value;
    setSearchType(value);
    setSelectedOptionValue(event.target.selectedOptions[0].text);
  };
  const handleSearchTypeChange2 = (event) => {
    const value = event.target.value;
    setSearchType2(value);
  };

  const { idioma } = useIdioma();
  let textosIdioma;
  switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }

  let forma = '';
  if (searchType === 'referencia') { forma = 'referencia'; }
  else if (searchType === 'contenedor') { forma = 'contenedor'; }
  else if (searchType === 'mbl') { forma = 'mbl'; }

  let forma2 = '';
  if (searchType2 === 'importacion') { forma2 = 'https://moduexpress.com/phpweb/ImpoCurlDb.php'; }
  else if (searchType2 === 'exportacion') { forma2 = 'https://moduexpress.com/phpweb/ExpoCurlDb.php'; }

  return (
    <>
      <div className="rastreo">
        <div className='titulo'><h2>{textosIdioma.RT}</h2></div>
        <div className='info'><p>{textosIdioma.RD}</p></div>
        <form className='search-bar' onSubmit={handleSubmit}>
          <select value={searchType2} onChange={handleSearchTypeChange2}>
            <option value="importacion" >{textosIdioma.RII}</option>
            <option value="exportacion">{textosIdioma.RIII}</option>
          </select>
          <select value={searchType} onChange={handleSearchTypeChange}>
            <option value="referencia">{textosIdioma.RR}</option>
            <option value="contenedor">{textosIdioma.RC}</option>
            <option value="mbl">MBL</option>
          </select>
        </form>
        <form className='search-bar2' onSubmit={handleSubmit}>
          <input
            className='search-input'
            type="text"
            placeholder={`${textosIdioma.RI} ${selectedOptionValue.toLowerCase()}...`}
            value={searchTerm}
            onChange={handleInputChange}
          />
          <button className='search-button' type="submit" onClick={fetchData} disabled={loading}>{loading ? textosIdioma.RL : textosIdioma.RB}</button>
        </form>
      </div>
      <div>
        {loading && showData === null ? (
          <div className='cargando'>
            <img src={Cargando} alt="GIF Animado" className='icono2' loop />
          </div>
        ) : null}
        {loading && showData !== null ? (
          <div className='cargando'>
            <img src={Cargando} alt="GIF Animado" className='icono2' loop />
          </div>
        ) : null}
        {!loading && showData === null ? (
          <div className='cargando'>
            <p>{textosIdioma.RW}</p>
          </div>
        ) : null}

        {showData === true && datos !== null ? (
          <>
            {Impo && !Expo ? (
              <DatosComponent datos={datos} idioma={idioma} />
            ) : !Impo && Expo ? (
              <DatosComponent2 datos={datos} idioma={idioma} />
            ) : null}
          </>
        ) : null}

        {!loading && showData === false ? (
          <div className='cargando'>
            <p>{textosIdioma.RN}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Rastreo;
