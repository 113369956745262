const textosEspañol = {
  //Idiomas
  I1: 'Español',
  I2: 'Inglés',
  I3: 'Chino',
  //Navbar
  N0: 'Inicio',
  N1: 'Servicios',
  N2: 'Contacto',
  N3: 'Rastreo',
  N4: 'Idioma',
  //Principal
  C1: {
    titulo: '¿QUIENES SOMOS?',
    descripcion: 'Somos una empresa logística o Forwarder, ubicados en la ciudad de Morelia, Michoacán. Desde el año 2008 nos hemos dedicado a brindar soluciones integrales en materia de comercio exterior, agilizando los procesos en importación y exportación, llevando tu carga hasta su destino y acompañándote durante todos los procesos para que cumplas con todos los requisitos jurídicos aplicables. Contamos con Alianzas que facilitan tu operación aduanera bajo los estándares de calidad que se merecen nuestros clientes, cuidando en todo momento su satisfacción y teniendo como eje rector la mejora continua.',
  },
  C2: {
    titulo: 'SERVICIOS',
    descripcion: 'En IPC ofrecemos servicios logísticos de acuerdo a tus necesidades para que puedas tener control durante todo el proceso en tu operación. Contamos con altos estándares de calidad para coadyuvar a la suma de acciones que faciliten todo el seguimiento de tu carga. Se cuenta con servicios de transporte en cualquiera de sus modalidades, importación y exportación, gestoría de trámites de comercio exterior, programas de fomento como IMMEX, PROSEC, IVA-IEPS, certificación en materia de seguridad OEA-CTPAT, asesoría legal en materia aduanera y tributaria, entre otros, con la finalidad de optimizar todos los procesos necesarios para agilizar tu operación.',
    boton: 'Conoce mas...',
  },
  C3: {
    titulo: 'CERTIFICACIÓN',
    descripcion:'Contamos con el reconocimiento de ATR (American Trust Register) en la norma NMX-CC-9001-IMNC-2015 (ISO 9001:2015). "Esto asegura que nuestros procesos están diseñados para capturar, gestionar y cumplir eficientemente los requerimientos de nuestros clientes, garantizando su satisfacción y fortaleciendo nuestras relaciones comerciales.',
    boton: '¡Ver Documento!',
  },
  C4: {
    titulo: 'POLITICA DE CALIDAD',
    descripcion:'En IMPORTADORA PACIFIC COAST nos dedicamos a brindar soluciones integrales en temas de logística nacional e internacional, así como de comercio exterior para nuestros clientes. Tenemos establecidos objetivos de calidad para obtener los mejores resultados; nos comprometemos a cumplir los requisitos legales aplicables y los de nuestras partes interesadas buscando la satisfacción en su totalidad para nuestros clientes y la mejora continua de nuestros procesos.',
    boton: 'Contactanos :)',
  },
  C5: {
    titulo: 'NUESTROS VALORES',
    descripcion: 'Nuestra empresa se guía por diez valores fundamentales: Competitividad, Mejora continua, Diversidad, Equidad, Lealtad al cliente, Prioridad al cliente, Respeto, Responsabilidad, Integridad y Orientación al personal. Estos valores nos impulsan a la excelencia, a promover un ambiente inclusivo, a priorizar la satisfacción del cliente, a actuar con integridad y a cuidar del bienestar de nuestro equipo. Son la base de nuestra cultura empresarial y nos motivan a seguir creciendo y mejorando constantemente.',
  },
  //Servicios
  S1: {
    titulo: 'Servicio de Transporte Terrestre, Marítimo, Aéreo y Ferroviario',
    descripcion:
      'Este servicio ofrece soluciones integrales para el transporte de carga a través de diversas modalidades: terrestre, marítima, aérea y ferroviaria. Este servicio se enfoca en garantizar que los bienes lleguen de manera segura y eficiente a su destino, eligiendo la opción de transporte más adecuada para cada tipo de carga.',
  },
  S2: {
    titulo: 'Servicio de Agenciamiento: Importación y Exportación',
    descripcion:
      'Este servicio está orientado a facilitar los trámites y procesos aduaneros relacionados con la importación y exportación de mercancías. Para ello, se realiza el análisis de los documentos y permisos necesarios, que estén en orden y cumpliendo con la norma jurídica aduanera aplicable, para realizar cada acto y/o formalidad en el tiempo más óptimo posible.',
  },
  S3: {
    titulo: 'Servicio de Revalidación de Guías para Importación',
    descripcion:
    'La revalidación de guías para importación es un procedimiento necesario para la liberación de tu carga, para ello contamos con procedimientos que agilizan el contacto y la gestión de trámites ante navieras y/o terminales para evitar retrasos en la revalidación de tus guías.',
  },
  S4: {
    titulo: 'Servicio de Previo en Origen de China',
    descripcion:
      'El servicio de previo en origen se refiere a la inspección y verificación de la carga antes de su embarque hacia el país de destino. Esto se realiza para asegurar que la mercancía cumpla con los estándares de calidad y seguridad establecidos, evitando problemas y retrasos en el proceso de importación. Las inspecciones pueden incluir aspectos como la documentación correcta, el estado físico de los bienes y el embalaje adecuado.',
  },
  S5: {
    titulo: 'Servicio de Almacenamiento y Maniobras en Patio Externo',
    descripcion:
      'Nuestra empresa brinda servicios de almacenamiento FCL/LCL y operaciones de carga y descarga en Manzanillo, México, y el patio de almacenamiento fuera de la Aduana de Lázaro.La tarifa de almacenamiento es gratuita durante 15 días, con el objetivo de brindar a los clientes un servicio más económico, conveniente y rápido que el patio de almacenamiento. dentro de la aduana servicios de depósito.',
    },
  S6: {
    titulo: 'Seguro de Carga/Contenedor',
    descripcion:
      'El seguro de carga o contenedor es un servicio que busca proteger económicamente a los propietarios o responsables de la carga en caso de daños o pérdidas durante el transporte. Este seguro cubre diversos riesgos, como accidentes, robo, daños por condiciones climáticas adversas u otros eventos imprevistos que puedan afectar la mercancía mientras está en tránsito.',
  },
  S7: {
    titulo: 'Gestoría de Trámites de Comercio Exterior',
    descripcion:
      'La gestoría de trámites de comercio exterior consiste en asesorar y representar a empresas o individuos en los procesos administrativos y legales relacionados con el comercio internacional. Los gestores de comercio exterior se encargan de realizar y gestionar trámites aduaneros, documentación, permisos, certificaciones y otros aspectos burocráticos necesarios para importar o exportar mercancías de forma legal y segura.',
  },
  S8: {
    titulo: 'Certificación en Programas de Fomento: IMMEX, PROSEC, IVA-IEPS',
    descripcion:
      'Los programas de fomento son esquemas establecidos por las autoridades gubernamentales para incentivar y promover la actividad económica y comercial en ciertos sectores o industrias. Entre ellos se encuentran el Programa IMMEX (Industria Manufacturera, Maquiladora y de Servicios de Exportación), el Programa PROSEC (Programas de Promoción Sectorial), y el IVA-IEPS (Impuesto al Valor Agregado e Impuesto Especial sobre Producción y Servicios). El servicio de certificación en estos programas consiste en asesorar y ayudar a las empresas a cumplir con los requisitos para obtener los beneficios fiscales y aduaneros que estos programas ofrecen.',
  },
  S9: {
    titulo: 'Certificación OEA-CTPAT',
    descripcion:
      'La certificación OEA (Operador Económico Autorizado) es un reconocimiento otorgado por las autoridades aduaneras a las empresas que han demostrado altos estándares de seguridad y cumplimiento aduanero en sus operaciones de comercio exterior. CTPAT (Customs-Trade Partnership Against Terrorism) es un programa similar implementado por las autoridades aduaneras de Estados Unidos. Ambos programas buscan agilizar los procesos aduaneros y mejorar la seguridad en la cadena de suministro. El servicio de certificación OEA-CTPAT se enfoca en asesorar y acompañar a las empresas para obtener y mantener estas certificaciones.',
  },
  S10: {
    titulo: 'Consultoria de comercio exterior',
    descripcion:
      'A través de nuestro conocimiento y experiencia brindamos servicios de asesoría y consultoría en temas aduaneros, fiscales y de comercio exterior, con el objetivo de reducir costos y gastos en el pago de contribuciones, así como asegurar el correcto cumplimiento de regulaciones y restricciones no arancelarias, NOMS y otras medidas exigibles en la importación y/o exportación, de igual forma buscamos evitar sanciones innecesarias que pudieran afectar el patrimonio de nuestros clientes.',
  },
  puntosDeServicio: {
    titulo: 'Puntos de servicio',
    lista: [
      'Norte America',
      'Latino America',
      'Asiá oriental',
      'Asiá del sur',
      'Medio Oriente',
      'Europa',
      'Africa',
    ],
  },

  //Contacto
  Span1: 'Llamanos al: ',
  Span2: 'Encuentrenos en: ',
  Span3: 'Mandanos un correo: ',
  Span4: 'Para quejas y sugerencias: ',
  Span5: 'Contáctanos para cotizaciones en WhatsApp: ',
  Span6: 'Contáctanos para calidad en WhatsApp: ',
  SpanD: 'Boulevard García de León 1521-Int. 101-A, Chapultepec Oriente, 58260 Morelia, Michoacan, Mexico',

  //Rastreo
  RT: 'Seguimiento de envío',
  RD: 'Se brinda información sobre el trayecto del su contenedor desde su inicio hasta su destino.',
  RR: 'Referencia',
  RC: 'Contenedor',
  RI: 'Ingrese el número de',
  RB: 'Buscar',
  RL: 'Cargando...',
  RW: 'Esperando datos para la búsqueda...',
  RN: 'No se encontraron resultados para la búsqueda',
  RII: 'Importación',
  RIII: 'Exportación',
  R0: 'INFORMACIÓN PRINCIPAL IMPORTACIÓN',
  R1: 'INFORMACIÓN PRINCIPAL EXPORTACIÓN',
  R2: 'NO. REFERENCE: ',
  R3: 'ENTRY: ',
  R4: 'CONTENEDOR: ',
  R5: 'RESGUARDO: ',
  R6: 'POL: ',
  R7: 'POD: ',
  R8: 'MODALIDAD: ',
  R9: 'DESTINO: ',
  R10: 'DETALLE DE HORARIOS: ',
  R11: 'CITA DE DESPACHO: ',
  R12: 'INICIO DE RUTA NACIONAL: ',
  R13: 'ETA A DESTINO: ',
  R14: 'EIR: ',
  R15: 'LLEGADA: ',
  R16: 'DESCARGA: ',
  R17: 'PATIO DE VACÍO: ',
  R18: 'ENTREGA DE VACÍO: ',
  R19: 'ENVÍO: ',
  R20: 'ÚLTIMO ESTADO DEL CONTENEDOR',
  R21: 'NÚMERO DE CONTENEDOR: ',
  R22: 'ESTATUS:  ',
  R23: 'LUGAR DE CARGA: ',
  R24: 'FECHA DE CARGA EN FABRICA: ',
  R25: 'NOMBRE DEL BUQUE: ',
  R26: 'FECHA ESTIMADA DE ARRIBO A DESTINO: ',
  R27: 'MARITIMO',
  R28: 'LUGAR DE RECOLECCION DE CONTENEDORES VACIOS: ',
  R29: 'FECHA DE TOMA DE CONTENEDORES VACIOS: ',
  R30: 'FECHA INICIO DE RUTA A LA FABRICA: ',
  R31: 'FECHA REAL EN QUE LLEGO A LA FABRICA: ',
  R32: 'TERMINO DE CARGAR:  ',
  R33: 'FECHA REAL DE LLEGADA AL PUERTO: ',
  R34: 'FECHA QUE SE INGRESO A LA TERMINAL: ',
  R35: 'TERMINAL: ',
  R36: 'TERRESTRE ',
  R37: 'TIPO DE VEHICULO: ',
  R38: 'FECHA DE LLEGADA DE CAMIÓN A PLANTA PARA CARGAR: ',
  R39: 'CONCLUSIÓN DE LA CARGA: ',
  R40: 'FECHA DE ARRIBO A ADUANA EN EUA: ',
  R41: 'FECHA DE CRUCE DE LA FRONTERA A USA: ',
  R42: 'FECHA DE CRUCE DE LA FRONTERA A CANADA: ',
  R43: 'FECHA DE ARRIBO A DESTINO FINAL: ',
  R44: 'FECHA Y HORA EN QUE FINALIZA LA DESCARGA: ',
  R45: 'DESPACHO ADUANA',
  R46: 'LLEGADA DESTINO',
  R47: 'DEVOLUCIÓN VACÍOS ',
  
  //Footer
  footer: 'Copyright ©2023 Derechos Reservados por Importadora Pacific Coast SA. de CV.',
  footer1: 'AVISO DE CONFIDENCIALIDAD',
  footer2: 'La información contenida en este correo electrónico, inlcuyendo en su caso los archivos adjuntos al mismo, se envían de IMPORTADORA PACIFIC COAST S.A. DE C.V. y puede contener información confidencial o reservada, si usted no es el destinatario del mismo, por favor notifique al remitente inmediatamente y borre este mensaje y cualquier copia del mismo, así como los archivos adjuntos. Debido a que las comunicaciones por internet no son seguras, en el caso de que usted no sea el destinatario de este correo IMPORTADORA PACIFIC COAST S.A. DE C.V. no aceptarán responsabilidad u obligación alguna por el contenido del mismo. La información de este mensaje, incluyendo, en su caso, los archivos adjuntos, se encuentra protegida por la Ley. Su reproducción, parcial o total, revisión, uso, revelación, y/o distribución indebida o no autorizada puede constituir un delito y/o contravenir diversas disposiciones legales. Con independencia de lo anterior, IMPORTADORA PACIFIC COAST S.A. DE C.V. se reservan el derecho de ejercer las acciones que correspondan, entre otros, por daños y perjuicios, con motivo del empleo indebidoy/o no autorizado de de la propia información.'
};

export default textosEspañol;