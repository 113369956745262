// IdiomaContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const IdiomaContext = createContext();

export function useIdioma() {
  return useContext(IdiomaContext);
}

export function IdiomaProvider({ children }) {
  
  const idiomasDisponibles = ['Español', 'Inglés', 'Chino']; 
  const [idioma, setIdioma] = useState(localStorage.getItem('idioma') || 'Español');

  useEffect(() => {
    localStorage.setItem('idioma', idioma);
  }, [idioma]);

  return (
    <IdiomaContext.Provider value={{ idioma, setIdioma, idiomasDisponibles }}>
      {children}
    </IdiomaContext.Provider>
  );
}
