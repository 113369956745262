import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Principal from "./Index2";
import { IdiomaProvider } from './components/IdiomaContext';

function App() {
  return (
    <IdiomaProvider>
      <Principal/>
    </IdiomaProvider>
  );
}

export default App;