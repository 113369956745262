// TextosChino.js
const textosChino = {
  //Idiomas
  I1: '西班牙语',
  I2: '英语',
  I3: '中文',
  //Navbar
  N0: '首页',
  N1: '服务',
  N2: '联系方式',
  N3: '货柜动态',
  N4: '语言',
  //Principal
  C1: {
    titulo: '关于我们',
    descripcion: '我们是一家位于墨西哥米却肯州莫雷利亚市的国际物流公司。公司成立于2008年，我司致力于提供全面，一站式的外贸解决方案：简化进出口流程，确保墨西哥进出口合法合规，并将您的货物送达指定目的地。我司深耕墨西哥物流市场多年，拥有品质，价格一流的服务。关注客户满意度，持续改进是我司开展业务的指导性原则。',
  },
  C2: {
    titulo: '服务',
    descripcion: '我司可根据您的需求定制物流服务，使您能够在整个外贸，物流业务中确保对外贸操作的控制权。我们秉承高质量的服务标准开展所有业务。服务内容包括多种运输服务（海运，空运，陆运），墨西哥进出口清关报关，墨西哥外贸合规手续办理，以及IMMEX，PROSEC（行业促进计划），增值税认证， OEA-CTPAT认证的办理，墨西哥外贸咨询等服务，旨在优化流程，提高外贸业务办理效率。',
    boton: '了解更多...'
  },
  C3: {
    titulo: 'ISO9001:2015认证',
    descripcion: '我司拥有ATR（美国信托注册机构）颁发的NMX-CC-9001-IMNC-2015（ISO 9001:2015）认证。 "我司通过质量管理体系确保流程的规范性，确保将客户满意度作为业务指导性原则，加强我司与所有业务伙伴的合作。。"',
    boton: '查看文件！'
  },
  C4: {
    titulo: '质量政策',
    descripcion: '我司专注提供墨西哥国内及国际一站式的外贸物流解决方案。我司高要求的质量目标推动团队在业务中取得最佳业绩；我们承诺遵守墨西哥使用法律要求，在合规的前提下追求最高客户满意度，不断进步。',
    boton: '联系我们 :)'
  },
  C5: {
    titulo: '我们的价值观',
    descripcion: '我司秉承十大基本价值观：保持竞争力，持续改进，尊重多样性，公平公正，对客户忠诚，客户至上，尊重个人，拥有责任感，诚实守信和以人为本。这些价值观推动我们朝着卓越迈进，创造一个包容，多样的工作环境，优先考虑客户满意度，诚实守信，关心团队福祉。这些价值观构成了我们公司企业文化的基础，激励我们不断成长和进步。',
  },
  //Servicios
  S1: {
    titulo: '陆路、海路、空运和铁路运输服务',
    descripcion: '该服务提供全面的货物运输解决方案，涵盖陆路、海路、空运和铁路等多种运输方式。我司负责协调和管理运输服务，以确保货物安全高效地抵达目的地，为每种货物选择最经济安全合适的运输选项。',
  },
  S2: {
    titulo: '进出口清关报关服务 ',
    descripcion: '进出口清关报关服务旨在确保进出口文件符合墨西哥海关要求，政府相关部门及海关要求的各项手续提前协调办理，确保外贸进出口手续高效快捷办理完成，避免产生码头仓储费或货柜滞期费，满足客户对货物接收时间上的要求。',
  },
  S3: {
    titulo: '进口换单/提单放行 ',
    descripcion: '在进行海运进口清关手续之前，必须得到船主放行的提单，否则清关手续不能继续，此服务旨在及时/提前按照船主要求满足其放行的付款及文件条件，确保提单按时放行，以便继续进口清关手续；空运运单放行服务指的是空运单必须按照墨西哥外贸法规定由进口方/具有资质的第三方服务公司在墨西哥税务系统上登记运单。',
  },
  S4: {
    titulo: '中国发货地预检服务',
    descripcion: '中国发货地预检服务是把墨西哥港口的预检工作提前到发货地，以减少墨西哥港口预检占用紧张的清关时间，产生额外费用如仓储费，滞期费。此外通过发货地预检提前确认进口货物信息，避免进口过程中出现问题和延误，确保发运货物符合墨西哥进口要求，预检报告与进口单据保持一致，提前预录进口报税单，做到货物到港即可清关。。',
  },
  S5: {
    titulo: '外部堆场仓储装卸作业服务',
    descripcion: '我们的公司在墨西哥曼萨尼略提供FCL/LCL仓储服务和装卸作业，同时还在拉萨罗·卡德纳斯海关外提供仓储场地服务。仓储费用在15天内免费，旨在为客户提供比海关内仓储场地更经济、便捷和迅速的服务，以及仓库存放服务。',
  },
  S6: {
    titulo: '货物/集装箱保险',
    descripcion: '大部分进口公司在货物/集装箱在到达墨西哥港口后，货物/集装箱处于无保险状态，货主承担着货物/货柜在墨西哥码头，运输途中遭受自然灾害，不可抗力，事故，盗窃，货损等等风险。我司强烈建议客户给货物/货柜投保。',
  },
  S7: {
    titulo: '外贸业务咨询',
    descripcion: '我们提供海关、外贸问题的咨询服务，旨在在法律允许范围内降低税金缴纳的成本和费用，并确保客户遵守墨西哥非关税法规和限制、NOMS要求以及进出口业务中其他的政府管制条件。同样，我们力求避免客户因不合规操作或其他原因而接受不必要制裁。',
  },
  S8: {
    titulo: '认证服务：制造，加工出口服务计划（IMMEX）、行业促进计划（PROSEC）、增值税认证(CERTIFICACION IVA-IEPS)',
    descripcion: '促进计划是政府机构制定的方案，旨在鼓励和促进特定行业或领域的经济和商业活动。其中包括IMMEX计划（制造、代工和出口服务产业）、PROSEC计划（部门促进计划）以及IVA-IEPS（增值税和特殊生产和服务税）。这些计划的认证服务包括为企业提供指导和协助，以满足获取这些计划所提供的财务和海关优惠的要求。',
  },
  S9: {
    titulo: 'OEA-CTPAT认证',
    descripcion: 'OEA（经认证经营者）认证是海关机构授予在国际贸易活动中高度安全运营并严格遵循海关要求的企业的认证。CTPAT（海关贸易合作打击恐怖主义计划）是由美国海关机构实施的类似计划。这两个计划旨在简化海关程序，提高供应链的安全性。我司为客户提供咨询服务和必要的支持，以获得并维持这些认证。',
  },
  S10: {
    titulo: '国际贸易咨询',
    descripcion: '通过我们的知识和经验，我们提供关于海关、税收和国际贸易的咨询和顾问服务。我们的目标是降低费用和贡献支付的支出，确保在进出口过程中适当遵守法规和非关税限制、NOMS以及其他必要措施。同样，我们旨在防止可能影响客户资产的不必要处罚。',
  },
  puntosDeServicio: {
    titulo: '全球服务点',
    lista: [
      '北美',
      '拉丁美洲',
      '东亚',
      '南亚',
      '中东',
      '欧洲',
      '非洲',
    ],
  },

  //Contacto
  Span1: '致电我们：',
  Span2: '地址：',
  Span3: '发送电子邮件：',
  Span4: '投诉和建议：',
  Span5: '致电我们：',
  Span6: '通过WhatsApp联系我们报价：',
  Span7: '通过WhatsApp联系我们申诉，或提供意见，建议',
  SpanD: 'Boulevard García de León 1521-Int. 101-A, Chapultepec Oriente, 58260 Morelia, Michoacan, Mexico ',

  RT: '货物跟踪',
  RD: '提供有关您的货柜从起点到目的地的行程信息。',
  RR: '单号',
  RC: '集装箱',
  RI: '请输入',
  RB: '搜索',
  RL: '加载中...',
  RW: '等待搜索数据...',
  RN: '未找到搜索结果',
  RII: '进口',
  RIII: '出口',
  R0: '进口主要信息',
  R1: '出口主要信息',
  R2: '单号: ',
  R3: '入口：',
  R4: '集装箱: ',
  R5: '代仓储: ',
  R6: 'POL: ',
  R7: 'POD: ',
  R8: '运输方式: ',
  R9: '目的地: ',
  R10: '具体运输信息: ',
  R11: '预约提柜: ',
  R12: '国内运输出发: ',
  R13: '预计抵达目的地时间: ',
  R14: 'EIR: ',
  R15: '到达: ',
  R16: '卸货: ',
  R17: '还空堆场: ',
  R18: '空柜返回: ',
  R19: '发运: ',
  R20: '集装箱最新状态',
  R21: '集装箱号码：',
  R22: '状态：城市之',
  R23: '装货地点：',
  R24: '目的地装货日期：',
  R25: '船名：',
  R26: '预计到达目的地日期：',
  R27: '海运',
  R28: '空集装箱提柜地点：',
  R29: '空集装箱提柜日期：',
  R30: '前往装柜地点出发日期：',
  R31: '实际到达装柜地点日期：',
  R32: '装船结束日期：',
  R33: '实际到达港口日期：',
  R34: '进入码头日期：',
  R35: '码头：',
  R36: '陆运',
  R37: '车辆类型：',
  R38: '卡车到达装货地装货日期：',
  R39: '装货完成日期：',
  R40: '进入美国海关日期：',
  R41: '过境美国边境日期：',
  R42: '过境加拿大边境日期：',
  R43: '到达最终目的地日期：',
  R44: '卸货完成日期和时间：',
  R45: '海关清关',
  R46: '目的地到达',
  R47: '空箱退还',
  //Footer
  footer: '©2023 版权所有，由Importadora Pacific Coast SA de CV保留所有权利。',
  footer1: '隐私声明',
  footer2: '此电子邮件中包含的信息，包括附件（如果有），是由IMPORTADORA PACIFIC COAST S.A. DE C.V.发送的，可能包含机密或保密信息。如果您不是收件人，请立即通知发件人并删除此消息及其任何副本，以及附件。由于互联网通信并不安全，如果您不是此电子邮件的收件人，IMPORTADORA PACIFIC COAST S.A. DE C.V. 不会对其内容承担任何责任或义务。此消息中的信息，包括附件（如果有），受法律保护。未经授权或未经授权的复制、部分或全部复制、审查、使用、披露和/或分发可能构成犯罪和/或违反各种法律法规。尽管如上所述，IMPORTADORA PACIFIC COAST S.A. DE C.V. 保留根据未经授权或未经授权使用本信息而采取适当行动的权利，包括但不限于采取损害赔偿等行动。',
};

export default textosChino;
