//React
import React,{ useState } from "react";
//Estilos
import './estilos/principal.css';
//Logos
import Logo from "./assets/Logos/IPOscuro.png";
//Componentes
import Carrusel2 from "./components/Carrusel2";
import Servicios from "./components/Servicios";
import Footer from "./components/Footer";
import Contacto from "./components/Contacto";
import Rastreo from "./components/Rastreo";
import BFW from "./components/BFW";
//Cambio de Idioma
import { useIdioma } from './components/IdiomaContext';
import textosEspañol from './components/TextosEspañol';
import textosIngles from './components/TextosIngles';
import textosChino from './components/TextosChino';

function Index2(){
  const [activeSection, setActiveSection] = useState('inicio');
  const handleNavClick = (section) => {
    setActiveSection(section);
    if (section === 'inicio') { return null; }  
  };
  const { idioma, setIdioma } = useIdioma();
  const cambiarIdioma = (nuevoIdioma) => {
    setIdioma(nuevoIdioma);
  };
  let textosIdioma;
  switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }

  return(
    <>
      <div className="menunavbar">
        <header>
          <img className="logo" src={Logo} alt="Logo"/>
          <button className={activeSection === 'inicio' ? 'active' : ''} onClick={() => handleNavClick('inicio')}>{textosIdioma.N0}</button>
          <div><button className={activeSection === 'servicios' ? 'active' : ''} onClick={() => handleNavClick('servicios')}>{textosIdioma.N1}</button></div>
          <div><button className={activeSection === 'contacto' ? 'active' : ''} onClick={() => handleNavClick('contacto')}>{textosIdioma.N2}</button></div>
          <div><button className={activeSection === 'rastreo' ? 'active' : ''} onClick={() => handleNavClick('rastreo')}>{textosIdioma.N3}</button></div>
          <div className="boton-desplegable">
            <button className="boton-desplegable">{textosIdioma.N4}</button>
            <div className="contenido-desplegable">
              <button onClick={() => cambiarIdioma(textosIdioma.I1)}>{textosIdioma.I1}</button>
              <button onClick={() => cambiarIdioma(textosIdioma.I2)}>{textosIdioma.I2}</button>
              <button onClick={() => cambiarIdioma(textosIdioma.I3)}>{textosIdioma.I3}</button>
            </div>
          </div>
        </header>
      </div>
      <section>
        {activeSection === 'inicio' && ( <Carrusel2 cambiarSeccion={handleNavClick}/> )}
        {activeSection === 'servicios' && ( <Servicios/> )}
        {activeSection === 'contacto' && ( <Contacto/> )}
        {activeSection === 'rastreo' && ( <Rastreo/> )}
        {activeSection !== 'inicio' && ( <Footer/> )}
        <BFW Numero={4434791352} />
      </section>
    </>
  );
};

export default Index2;