import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import Certificado from '../assets/Logos/ISO90012015.png';

const BotonFlotanteWhatsapp = ({ Numero }) => {
  const [zoomed, setZoomed] = useState(false);

  const handleWhatsAppClick = () => {
    const message = 'Hola, me interesaría saber más información de la empresa.';
    const url = `https://api.whatsapp.com/send?phone=${Numero}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    const zoomTimer = setInterval(() => {
      setZoomed(prevZoomed => !prevZoomed);
    }, 700); // Cambia el intervalo de tiempo según tus preferencias (en milisegundos)

    return () => clearInterval(zoomTimer);
  }, []);

  return (
    <div className="boton-flotante-container">
      <div className={`BFW ${zoomed ? 'zoomed' : ''}`} onClick={handleWhatsAppClick} title="Envíanos un WhatsApp">
        <FaWhatsapp size={30} />
      </div>
      <div className={`BFW1 ${zoomed ? 'zoomed' : ''}`}>
        <img src={Certificado} alt='Certificado' className='icono3'></img>
      </div>
    </div>
  );
};

export default BotonFlotanteWhatsapp;
