import React from 'react';
import './Timeline.css'; // Archivo CSS para estilos
import S1 from '../assets/Carrusel/1.svg';
import S2 from '../assets/Carrusel/2.svg';
import S3 from '../assets/Carrusel/3.svg';

const TimelineEvent = ({ image, header, content }) => {
  return (
    <div className="timeline-event">
      <div className="event-image">
        <img src={image} alt="Imagen del evento" />
      </div>
      <div className="event-info">
        <div className="event-header">{header}</div>
        <div className="event-content">{content}</div>
      </div>
    </div>
  );
};

const P = () => {
  return (
    <div className="timeline-container"> <TimelineEvent image= {S1} header="POR" content="Shanghái, SH, Shanghái, China, Regreso cargado" />
      <TimelineEvent image= {S2} header="Primer POL" content="Llevar a la fuerza, ATD 2022-12-12 11:19" />
      <TimelineEvent image= {S2} header="Último POD" content="Manzanillo, ATA 2022-12-29 20:43" />
      <TimelineEvent image= {S3} header="F.N.D." content="Manzanillo, CL, México, 2023-01-02 00:21" />
    </div>
  );
};

export default P;
