import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import Consulta from './Consulta';
import Rastreo from './components/Rastreo';
import P from './components/P';
import Admin from './components/Admin';

const Union = () => {
  return (
    <Router>
      <Route exact path="/" component={App} />
      <Route exact path="/consulta" component={Consulta} />
      <Route exact path="/rastreo" component={Rastreo} />
      <Route exact path="/p" component={P} />
      <Route exact path="/admin" component={Admin} />
    </Router>
  );
};

export default Union;
