//React
import React from "react";
//Iconos
import Telefono from '../assets/Iconos/Telefono.svg';
import Correo from '../assets/Iconos/Correo.svg';
import Ubicacion from '../assets/Iconos/Localizacion.svg';
import Whatsapp from '../assets/Iconos/Whatsapp.svg';
//Cambio de Idioma
import { useIdioma } from './IdiomaContext';
import textosEspañol from './TextosEspañol';
import textosIngles from './TextosIngles';
import textosChino from './TextosChino';

function Contacto(){
    const { idioma } = useIdioma();
    let textosIdioma;
      switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }
    return(
    <div className="contacto">
        <div className="mapa">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423.5043467587547!2d-101.16424882522563!3d19.68819430715851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0df835033b65%3A0x983f93abaa0b6ad9!2sIMPORTADORA%20PACIFIC%20COAST%20S.A.%20DE%20C.V.!5e0!3m2!1ses!2smx!4v1689008309610!5m2!1ses!2smx"  title="map" width={'500px'} height={'400px'} loading="lazy"></iframe>
        </div>
        <div className='text-contacto'>
            <span>{textosIdioma.Span1}</span>
            <a href={`tel:4436886443`} onClick={()=>{ window.location.href = `tel:4436886443`}} >
            <div className='text2-contacto'><img src={Telefono} alt ="telefono" className='icono'/><h1>4436886443</h1></div>
            </a>
            <span>{textosIdioma.Span2}</span>
            <a href={`https://www.google.com/maps/place/IMPORTADORA+PACIFIC+COAST+S.A.+DE+C.V./@19.6884577,-101.1645174,19.92z/data=!4m6!3m5!1s0x842d0df835033b65:0x983f93abaa0b6ad9!8m2!3d19.6882576!4d-101.1641397!16s%2Fg%2F11spldhshd?entry=ttu`} onClick={()=>{ window.location.href = `https://www.google.com/maps/place/IMPORTADORA+PACIFIC+COAST+S.A.+DE+C.V./@19.6884577,-101.1645174,19.92z/data=!4m6!3m5!1s0x842d0df835033b65:0x983f93abaa0b6ad9!8m2!3d19.6882576!4d-101.1641397!16s%2Fg%2F11spldhshd?entry=ttu`}} >
            <div className='text2-contacto'><img src={Ubicacion} alt="ubicacion" className='icono'/><h1>{textosIdioma.SpanD}</h1></div>
            </a>
            <span>{textosIdioma.Span3}</span>
            <a href={`mailto:logistica@impopacific.com`} onClick={()=>{ window.location.href = `mailto:logistica@impopacific.com`}} >
            <div className='text2-contacto'><img src={Correo} alt="correo" className='icono'/><h1>logistica@impopacific.com</h1></div>
            </a>
            <a href={`mailto:trafico@impopacific.com`} onClick={()=>{ window.location.href = `mailto:trafico@impopacific.com`}} >
            <div className='text2-contacto'><img src={Correo} alt="correo" className='icono'/><h1>trafico@impopacific.com</h1></div>
            </a>
            <span>{textosIdioma.Span4}</span>
            <a href={`mailto:calidad@impopacific.com`} onClick={()=>{ window.location.href = `mailto:calidad@impopacific.com`}} >
            <div className='text2-contacto'><img src={Correo} alt="correo" className='icono'/><h1>calidad@impopacific.com</h1></div>
            </a>
            <span>{textosIdioma.Span5}</span>
            <a href={`https://wa.me/+5217531069248`} onClick={()=>{ window.location.href = `https://wa.me/+5217531069248`}} >
            <div className='text2-contacto'><img src={Whatsapp} alt="watsapp" className='icono'/><h1>753 106 9248</h1></div>
            </a>
            <a href={`https://wa.me/+5214434791352`} onClick={()=>{ window.location.href = `https://wa.me/+5214434791352`}} >
            <div className='text2-contacto'><img src={Whatsapp} alt="watsapp" className='icono'/><h1>443 479 1352</h1></div>
            </a>
            <span>{textosIdioma.Span6}</span>
            <a href={`https://wa.me/+524434063105`} onClick={()=>{ window.location.href = `https://wa.me/+524434063105`}} >
            <div className='text2-contacto'><img src={Whatsapp} alt="watsapp" className='icono'/><h1>443 406 3105</h1></div>
            </a>
        </div>
    </div>
    );
}
export default Contacto;