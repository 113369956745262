import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Imagen1 from '../assets/Carrusel2/1.jpg';
import Imagen2 from '../assets/Carrusel2/2.jpg';
import Imagen3 from '../assets/Carrusel2/6.jpg';
import Imagen4 from '../assets/Carrusel2/4.jpg';
import Imagen5 from '../assets/Carrusel2/5.jpg';
import CerificacionPDF from '../assets/Carrusel2/CertificadoPDF.jpg';
//Cambio de Idioma
import { useIdioma } from './IdiomaContext';
import textosEspañol from './TextosEspañol';
import textosIngles from './TextosIngles';
import textosChino from './TextosChino';

function Carrusel2({cambiarSeccion }) {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => { setIndex(selectedIndex);};
  const CambiarAServicios = () => { cambiarSeccion('servicios');};
  const CambiarAContacto = () => { cambiarSeccion('contacto');};
  const AbrirCertificadoPDF = () => {
    //const pdfURL = 'https://www.example.com/ruta/al/pdf.pdf'; Cuando sea link
    window.open(CerificacionPDF, '_blank');
  };
  const { idioma } = useIdioma();
  let textosIdioma;
    switch (idioma) {
    case 'Español': case 'Spanish': case '西班牙语': textosIdioma = textosEspañol; break;
    case 'Inglés': case 'English': case '英语': textosIdioma = textosIngles; break;
    case 'Chino': case 'Chinese': case '中文': textosIdioma = textosChino; break;
    default: textosIdioma = textosEspañol; break;
  }
  return (
    <div>
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item className="imgCarousel">
        <img src={Imagen1} alt="First slide"/>
        <Carousel.Caption>
          <h3>{textosIdioma.C1.titulo}</h3>
          <p>{textosIdioma.C1.descripcion}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="imgCarousel">
      <img src={Imagen2} alt="sec slide"/>
        <Carousel.Caption>
          <h3>{textosIdioma.C2.titulo}</h3>
          <p>{textosIdioma.C2.descripcion}</p>
          <button onClick={CambiarAServicios}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
              {textosIdioma.C2.boton}
          </button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="imgCarousel">
      <img src={Imagen4} alt="ter slide"/>
        <Carousel.Caption >
          <h3>{textosIdioma.C3.titulo}</h3>
          <p>{textosIdioma.C3.descripcion}</p>
          <button onClick={AbrirCertificadoPDF}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {textosIdioma.C3.boton}
          </button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="imgCarousel">
      <img src={Imagen3} alt="ter slide"/>
        <Carousel.Caption >
          <h3>{textosIdioma.C4.titulo}</h3>
          <p>{textosIdioma.C4.descripcion}</p>
          <button onClick={CambiarAContacto}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {textosIdioma.C4.boton}
          </button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="imgCarousel">
      <img src={Imagen5} alt="ter slide"/>
        <Carousel.Caption >
          <h3>{textosIdioma.C5.titulo}</h3>
          <p>{textosIdioma.C5.descripcion}</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default Carrusel2;