// TextosIngles.js
const textosIngles = {
  //Idiomas
  I1: 'Spanish',
  I2: 'English',
  I3: 'Chinese',
  //Navbar
  N0: 'Home',
  N1: 'Services',
  N2: 'Contact',
  N3: 'Tracking',
  N4: 'Language',
  //Principal
  C1: {
    titulo: 'WHO WE ARE?',
    descripcion: 'We are a logistics company or Forwarder, located in the city of Morelia, Michoacán. Since 2008, we have been dedicated to providing comprehensive solutions in foreign trade, streamlining import and export processes, delivering your cargo to its destination, and accompanying you throughout all the processes to comply with all applicable legal requirements. We have Alliances that facilitate your customs operation under the quality standards that our clients deserve, always taking care of their satisfaction and having continuous improvement as our guiding principle.',
  },
  C2: {
    titulo: 'SERVICES',
    descripcion: 'At IPC, we offer logistic services tailored to your needs, allowing you to have control throughout the entire operation. We have high-quality standards to contribute to all the actions that streamline the tracking of your cargo. We provide transportation services in any of its modalities, import and export management, foreign trade paperwork, incentive programs such as IMMEX, PROSEC, IVA-IEPS, certification in OEA-CTPAT security matters, legal advice on customs and tax matters, among others, with the aim of optimizing all the necessary processes to expedite your operation.',
    boton: 'Learn more...'
  },
  C3: {
    titulo: 'CERTIFICATION',
    descripcion: 'We have the recognition from ATR (American Trust Register) under the NMX-CC-9001-IMNC-2015 standard (ISO 9001:2015). "This ensures that our processes are designed to efficiently capture, manage, and fulfill our customers requirements, guaranteeing their satisfaction and strengthening our business relationships.',
    boton: 'View Document!'
  },
  C4: {
    titulo: 'QUALITY POLICY',
    descripcion: 'At IMPORTADORA PACIFIC COAST, we are dedicated to providing comprehensive solutions in national and international logistics, as well as foreign trade for our clients. We have established quality objectives to achieve the best results; we are committed to complying with applicable legal requirements and those of our interested parties, seeking total customer satisfaction and continuous improvement of our processes.',
    boton: 'Contact Us :)'
  },
  C5: {
    titulo: 'OUR VALUES',
    descripcion: 'Our company is guided by ten fundamental values: Competitiveness, Continuous Improvement, Diversity, Equity, Customer Loyalty, Customer Priority, Respect, Responsibility, Integrity, and People Orientation. These values drive us towards excellence, promoting an inclusive environment, prioritizing customer satisfaction, acting with integrity, and taking care of the well-being of our team. They form the basis of our corporate culture and motivate us to continue growing and improving constantly.',
  },
  S1: {
    titulo: 'Transportation Service: Land, Sea, Air, and Rail',
    descripcion: 'This service offers comprehensive solutions for the transportation of goods and passengers through various modalities: land, sea, air, and rail. The company or entity providing this service is responsible for coordinating and managing logistics to ensure that goods or people arrive safely and efficiently at their destination, choosing the most appropriate transportation option for each type of cargo or itinerary.',
  },
  S2: {
    titulo: 'Agency Service: Import and Export',
    descripcion: 'This service is aimed at facilitating customs procedures and processes related to the import and export of goods. A customs agent or customs broker represents the importer or exporter before customs and government authorities, ensuring that the necessary documents and permits are in order, complying with current regulations, and achieving an agile and efficient flow of international trade operations.',
  },
  S3: {
    titulo: 'Import Guide Revalidation Service',
    descripcion: 'The revalidation of import guides is a necessary procedure for some products controlled or regulated by customs authorities. This service involves managing and obtaining the required guides or permits to import certain goods subject to restrictions, such as chemicals, weapons, or products with special health regulations.',
  },
  S4: {
    titulo: 'Pre-shipment Service from China',
    descripcion: 'The pre-shipment service refers to the inspection and verification of cargo before shipping to the destination country. This is done to ensure that the goods meet established quality and safety standards, avoiding problems and delays in the importation process. Inspections may include aspects such as correct documentation, the physical condition of the goods, and appropriate packaging.',
  },
  S5: {
    titulo: 'Storage and Handling Service in External Yard',
    descripcion: 'Our company provides FCL/LCL storage services and loading/unloading operations in Manzanillo, Mexico, as well as storage yard services outside the Lázaro Cardenas Customs. The storage fee is free for up to 15 days, aiming to offer customers a more cost-effective, convenient, and swift service than the storage yard within the customs warehouse deposit services.',
  },
  S6: {
    titulo: 'Cargo/Container Insurance',
    descripcion: 'Cargo or container insurance is a service that seeks to economically protect the owners or responsible parties for the cargo in case of damage or loss during transportation. This insurance covers various risks, such as accidents, theft, damage due to adverse weather conditions, or other unforeseen events that may affect the goods while in transit.',
  },
  S7: {
    titulo: 'Foreign Trade Procedures Management',
    descripcion: 'Foreign trade procedures management consists of advising and representing companies or individuals in administrative and legal processes related to international trade. Foreign trade managers are responsible for carrying out and managing customs procedures, documentation, permits, certifications, and other bureaucratic aspects necessary for importing or exporting goods legally and safely.',
  },
  S8: {
    titulo: 'Certification in Promotion Programs: IMMEX, PROSEC, IVA-IEPS',
    descripcion: 'Promotion programs are schemes established by government authorities to encourage and promote economic and commercial activity in certain sectors or industries. Among them are the IMMEX Program (Manufacturing, Maquiladora, and Export Services Industry), the PROSEC Program (Sectoral Promotion Programs), and the IVA-IEPS (Value Added Tax and Special Tax on Production and Services). The certification service for these programs involves advising and assisting companies in meeting the requirements to obtain the fiscal and customs benefits that these programs offer.',
  },
  S9: {
    titulo: 'OEA-CTPAT Certification',
    descripcion: 'The OEA (Authorized Economic Operator) certification is a recognition granted by customs authorities to companies that have demonstrated high security and customs compliance standards in their foreign trade operations. CTPAT (Customs-Trade Partnership Against Terrorism) is a similar program implemented by U.S. customs authorities. Both programs aim to streamline customs processes and improve security in the supply chain. The OEA-CTPAT certification service focuses on advising and supporting companies to obtain and maintain these certifications.',
  },
  S10: {
    titulo: 'Foreign Trade Consulting',
    descripcion: 'Through our knowledge and experience, we offer advisory and consultancy services in customs, tax, and foreign trade matters. Our goal is to reduce costs and expenses related to contribution payments, ensure proper compliance with regulations and non-tariff restrictions, NOMs, and other required measures in import and/or export processes. Similarly, we aim to prevent unnecessary penalties that could impact our clients assets.',
  },
  puntosDeServicio: {
    titulo: 'Service Points',
    lista: [
      'North America',
      'Latin America',
      'East Asia',
      'South Asia',
      'Middle East',
      'Europe',
      'Africa',
    ],
  },

  //Contacto
  Span1: 'Call us at: ',
  Span2: 'Find us at: ',
  Span3: 'Send us an email: ',
  Span4: 'For complaints and suggestions: ',
  Span5: 'Call us at: ',
  Span6: 'Contact us for quotes on WhatsApp: ',
  Span7: 'Contact us for quality on WhatsApp: ',
  SpanD: 'Boulevard García de León 1521-Int. 101-A, Chapultepec Oriente, 58260 Morelia, Michoacán, México',

  //Rastreo
  RT: 'Tracking of shipment',
  RD: 'Information is provided about the journey of your container from its origin to its destination.',
  RR: 'Reference',
  RC: 'Container',
  RI: 'Enter the number of',
  RB: 'Search',
  RL: 'Loading...',
  RW: 'Waiting for data for the search...',
  RN: 'No results found for the search',
  RII: 'Import',
  RIII: 'Export',
  R0: 'MAIN IMPORT INFORMATION',
  R1: 'MAIN EXPORT INFORMATION',
  R2: 'REFERENCE NO.: ',
  R3: 'ENTRY: ',
  R4: 'CONTAINER: ',
  R5: 'CUSTODY: ',
  R6: 'POL: ',
  R7: 'POD: ',
  R8: 'MODE OF TRANSPORT: ',
  R9: 'DESTINATION: ',
  R10: 'SCHEDULE DETAILS: ',
  R11: 'DISPATCH APPOINTMENT: ',
  R12: 'START OF NATIONAL ROUTE: ',
  R13: 'ESTIMATED TIME OF ARRIVAL AT DESTINATION: ',
  R14: 'EIR: ',
  R15: 'ARRIVAL: ',
  R16: 'DISCHARGE: ',
  R17: 'EMPTY YARD: ',
  R18: 'EMPTY RETURN: ',
  R19: 'SHIPMENT: ',
  R20: 'CONTAINER LATEST STATUS',
  R21: 'CONTAINER NUMBER: ',
  R22: 'STATUS: CITY OF ',
  R23: 'LOADING LOCATION: ',
  R24: 'FACTORY LOADING DATE: ',
  R25: 'VESSEL NAME: ',
  R26: 'ESTIMATED ARRIVAL DATE AT DESTINATION: ',
  R27: 'MARITIME',
  R28: 'EMPTY CONTAINER COLLECTION LOCATION: ',
  R29: 'EMPTY CONTAINER PICKUP DATE: ',
  R30: 'START DATE OF ROUTE TO FACTORY: ',
  R31: 'ACTUAL DATE OF ARRIVAL AT FACTORY: ',
  R32: 'LOADING TERMINATION: ',
  R33: 'ACTUAL DATE OF ARRIVAL AT PORT: ',
  R34: 'DATE ENTERED INTO TERMINAL: ',
  R35: 'TERMINAL: ',
  R36: 'LAND TRANSPORT',
  R37: 'VEHICLE TYPE: ',
  R38: 'TRUCK ARRIVAL DATE AT PLANT FOR LOADING: ',
  R39: 'LOADING CONCLUSION: ',
  R40: 'DATE OF ARRIVAL AT USA CUSTOMS: ',
  R41: 'DATE OF CROSSING USA BORDER: ',
  R42: 'DATE OF CROSSING CANADA BORDER: ',
  R43: 'DATE OF ARRIVAL AT FINAL DESTINATION: ',
  R44: 'DATE AND TIME OF UNLOADING COMPLETION: ',
  R45: 'CUSTOMS CLEARANCE',
  R46: 'DESTINATION ARRIVAL',
  R47: 'EMPTY RETURNS',

  //Footer
  footer: '©2023 All Rights Reserved by Importadora Pacific Coast SA de CV.',
  footer1: 'CONFIDENTIALITY NOTICE',
  footer2: 'The information contained in this email, including any attachments thereto, is sent by IMPORTADORA PACIFIC COAST S.A. DE C.V. and may contain confidential or privileged information. If you are not the intended recipient, please immediately notify the sender and delete this message and any copies thereof, as well as the attachments. Because internet communications are not secure, if you are not the intended recipient, IMPORTADORA PACIFIC COAST S.A. DE C.V. will not accept liability or obligation for the content of this message. The information in this message, including any attachments thereto, is protected by law. Unauthorized or unauthorized reproduction, whole or in part, review, use, disclosure, and/or improper or unauthorized distribution may constitute a crime and/or violate various legal provisions. Despite the foregoing, IMPORTADORA PACIFIC COAST S.A. DE C.V. reserves the right to take appropriate action for unauthorized or unauthorized use of this information, including but not limited to seeking damages.',
};

export default textosIngles;
