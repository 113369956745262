import React, { useState } from 'react';

const Consulta = () => {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {

    setLoading(true);
    setError(null);

    const dataa = {
      action: 'tracking',
      id_tracking: 'TIPC22KH172-2'
    };
    try {
      // Realiza la solicitud POST
      const response = await fetch('https://rinoxlmx.com/phpappweb/tracking.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataa)
      });

      const json = await response.json();
      setData(json);
      
      console.log(json);

    } catch (error) {
      console.log('Error en la solicitud:', error);
    }
    setLoading(false);

  };
  const handlePrintData = () => {
    console.log(data); // Imprimir en la consola
    // Otras acciones para mostrar los datos en la interfaz
    setData(data);
  };
  return (
    <div>
      <button onClick={fetchData} disabled={loading}>
        Obtener Datos
      </button>

      {loading && <p>Cargando datos...</p>}
      {error && <p>{error}</p>}
      {data && (
        <div>
nO
      </div>

      )}
    </div>
  );
};

export default Consulta;
