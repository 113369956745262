import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, CircularProgress, Alert, TextField, Button,
    Select, MenuItem, FormControl, InputLabel, Dialog, DialogActions,
    DialogContent, DialogTitle
} from '@mui/material';
import './admin.css';

const ConfigData = () => {
    const [configData, setConfigData] = useState(null);
    const [loading, setLoading] = useState(false); // Cambiado a false para mostrar primero el Dialog
    const [error, setError] = useState(null);
    const [editedData, setEditedData] = useState({});
    const [dataFor, setDataFor] = useState('importacion');
    const [password, setPassword] = useState('');
    const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(true);

    // Función para obtener los datos desde la API
    const fetchConfigData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://moduexpress.com/phpweb/getshareparam.php', {
                params: { dataFor, password }
            });

            if (response.data.status === 'success') {
                setConfigData(response.data.data);
                const initialEditedData = response.data.data[0];
                setEditedData(initialEditedData);
                setError(null); // Limpiar error si la clave es correcta
            } else {
                setError("No se encontró la configuración o clave incorrecta");
            }
        } catch (err) {
            setError("Error al obtener los datos: " + err.message);
        } finally {
            setLoading(false);
        }
    };

    // Manejar la entrada de la clave y cargar datos al confirmarla
    const handlePasswordSubmit = () => {
        if (password.trim()) {
            setIsPasswordDialogOpen(false);
            fetchConfigData();
        } else {
            alert("Por favor, ingresa una clave");
        }
    };

    // Manejar cambios en los campos editables
    const handleChange = (key, value) => {
        setEditedData((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    useEffect(() => {
        fetchConfigData();
    }, [dataFor]);
    
    // Enviar el dato actualizado al backend
    const handleSave = async (key) => {
        const updatedValue = editedData[key];
        try {
            const response = await axios.post('https://moduexpress.com/phpweb/updateShareParam.php', {
                key,
                value: updatedValue,
                dataFor,
                password
                
            });

            if (response.data.status === 'success') {
                setConfigData((prevData) => prevData.map(item => ({
                    ...item,
                    [key]: updatedValue
                })));
                alert("Valor actualizado correctamente");
            } else {
                alert("Error al actualizar el valor o clave incorrecta");
            }
            fetchConfigData();

        } catch (err) {
            alert("Error al enviar la solicitud: " + err.message);
        }
    };

    // Si la clave no ha sido ingresada, mostrar el diálogo
    if (isPasswordDialogOpen) {
        return (
            <Dialog open={isPasswordDialogOpen}>
                <DialogTitle>Ingrese la Clave</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        type="password"
                        label="Clave"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePasswordSubmit} variant="contained" color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    // Mostrar el spinner solo después de ingresar la clave y cargar los datos
    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>Configuración del Archivo Excel</Typography>

            {/* Selector para Importación/Exportación */}
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Tipo de Configuración</InputLabel>
                <Select
                    value={dataFor}
                    onChange={(e) => setDataFor(e.target.value)}
                >
                    <MenuItem value="importacion">Importación</MenuItem>
                    <MenuItem value="exportacion">Exportación</MenuItem>
                </Select>
            </FormControl>

            {configData && configData.length > 0 && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Campo</b></TableCell>
                                <TableCell><b>Valor</b></TableCell>
                                <TableCell><b>Acción</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(configData[0]).map(([key, value]) =>
                                key !== 'id' && (
                                    <TableRow key={key}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                defaultValue={value}
                                                onChange={(e) => handleChange(key, e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSave(key)}
                                                disabled={editedData[key] === value}
                                            >
                                                Guardar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default ConfigData;
